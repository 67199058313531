import { Component, ViewChild, ViewEncapsulation, OnInit} from '@angular/core';
import { Router,ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { EncrDecrService } from './shared/services/EncrDecr.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    currentUrl='home';
    profile:any = null;
    role='user';
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private encService :EncrDecrService
    ){
        this.router.events.subscribe((event: Event) => {
          
            if (event instanceof NavigationEnd) {   
                console.log(event.url);    
                if(event.url=='/'){
                    this.currentUrl ='home';
                }else{
                    this.currentUrl = event.url;
                }
            }
            // get user profile 
            if (localStorage.getItem('key') && (localStorage.getItem('key') != null)) {
                let userProfile = localStorage.getItem('key')
                this.profile = JSON.parse(this.encService.get('2020$#@$^@piseth',userProfile));
                this.role = this.profile[0].role;
                if(this.role =='user'){
                    if((new Date().getTime()-this.profile[0].last_log) > 86400002){
                        localStorage.clear();
                        window.location.href="#/login";
                    }
                }
            }else{
                this.profile=null
            }

        });
    }
    ngOnInit() {

    }
}
