import {Component, OnInit} from '@angular/core';
import { FirestoreServices  } from '../shared/services/firestore.service';

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.css"]
  })
  export class HomeComponent implements OnInit{
    slide='';
    scan_qr='';
    big_promotion='';
    explore_product='';
    banner1='/assets/images/caro1.jpg';
    banner2='/assets/images/caro2.jpg';
    banner3='/assets/images/caro3.jpg';
    constructor(
        private db:FirestoreServices
    ){

    }
    ngOnInit() {
        this.db.getHomePage().subscribe(
            data=>{
                this.slide = data['slide1'];
                this.scan_qr =data['scan_qr'];
                this.big_promotion =data['big_promotion'];
                this.explore_product = data['explore_product'];
                this.banner1 = data['banner1'];
                this.banner2 = data['banner2'];
                this.banner3 = data['banner3'];
            },err=>{
                console.log(err);
            }
        );
    }

  }