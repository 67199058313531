import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// component
import { GenerateQRComponent } from '../app/generate-qr/generate-qr.component';
import { ScanQRComponent } from '../app/scan-qr/scan-qr.component';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RewardComponent } from './reward/reward.component';
import { ClaimRewardComponent } from './claim-reward/claim-reward.component';
import { PromotionComponent } from './promotion/promotion.component';
import { EventComponent } from './event/event.component';
import { AuthGuard } from './shared/services/authentication/auth-guard.service';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { title: 'Home Page' }, 
  }, 
  {
    // load component route with lazy loading
    path: 'page', 
    loadChildren: () => import('./sub-app.module').then(m => m.SubAppModule)
  }, 
//   {
//     path: 'reward',
//     component: RewardComponent,
//     data: { title: 'Reward Page' }, 
//   }, 
//   {
//     path: 'generate-qr',
//     component: GenerateQRComponent,
//     canActivate: [AuthGuard],
//     data: { title: 'Generate QR Page'}
//   }, 
//   {
//     path: 'scan-qr',
//     component: ScanQRComponent,
//     canActivate: [AuthGuard],
//     data: { title: 'Scan QR Page'}
//   },
//   {
//     path: 'user',
//     component: UserComponent,
//     canActivate: [AuthGuard],
//     data: { title: 'User Page'}
//   }, 
//   {
//     path: 'claim-reward',
//     component: ClaimRewardComponent,
//     canActivate: [AuthGuard],
//     data: { title: 'Claim Reward Page'}
//   }, 
//   {
//     path: 'profile',
//     component: ProfileComponent,
//     canActivate: [AuthGuard],
//     data: { title: 'Profile Page' }, 
//   },  
//   {
//     path: 'login',
//     component: LoginComponent,
//     data: { title: 'Login Page' }, 
//   }, 
//   {
//     path: 'register',
//     component: RegisterComponent,
//     data: { title: 'Register Page' }, 
//   },  
//   {
//     path: 'event',
//     component: EventComponent,
//     data: { title: 'Event Page' }, 
//   },  
//   {
//     path: 'promotion',
//     component: PromotionComponent,
//     data: { title: 'Promotion Page' }, 
//   },  
  {path: '**', redirectTo:''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
