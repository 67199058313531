import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// component
import { GenerateQRComponent } from '../app/generate-qr/generate-qr.component';
import { ScanQRComponent } from '../app/scan-qr/scan-qr.component';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RewardComponent } from './reward/reward.component';
import { ClaimRewardComponent } from './claim-reward/claim-reward.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PromotionComponent } from './promotion/promotion.component';
import { EventComponent } from './event/event.component';
// third party
import { environment } from '../environments/environment';
import {
    HttpClientModule
} from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AuthGuard } from './shared/services/authentication/auth-guard.service';

@NgModule({
  declarations: [
    AppComponent,
    // GenerateQRComponent,
    // ScanQRComponent,
    HomeComponent,
    // UserComponent,
    // ProfileComponent,
    // LoginComponent,
    // RegisterComponent,
    // RewardComponent,
    // ClaimRewardComponent,
    // PromotionComponent,
    // EventComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig,'QRScanner'),
    AngularFirestoreModule,
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
