import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,Router,ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { FirestoreServices } from '../firestore.service';

@Injectable()
export class AuthGuard implements CanActivate {
  admin_url =['/user','/scan-qr','/generate-qr'];
  constructor(
              private authService: FirestoreServices,   
              private router: Router, 
              private route: ActivatedRoute,
    
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let role = this.authService.getRole();

    if ( this.authService.isAuthenticated() ) {
         if(role =="user" && this.admin_url.includes(state.url)){
            this.router.navigate(['/']);
         }
         
         return true;
    }
    // navigate to login page
    this.router.navigate(['/login']);
    // you can save redirect url so after authing we can move them back to the page they requested
    //return false;
  } 
}
