import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';   
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { HttpClient,HttpRequest } from '@angular/common/http';
import {formatDate} from '@angular/common'; 
import { first, map } from 'rxjs/operators';
import { AngularFirestore , } from '@angular/fire/firestore';
import { EncrDecrService } from './EncrDecr.service';
@Injectable({
    providedIn: 'root'
})

export class FirestoreServices { 
  decypt:any;
  constructor(
    private firestore: AngularFirestore ,
    private http: HttpClient,
    private Enc:EncrDecrService
  ) { } 
   
    getUserList(lasteRecord) {  
       return this.firestore.collection('user',
       ref =>{
           if(lasteRecord == null){
            return ref
            .where("role", "==" , 'user')
            .orderBy('timeStamp','desc') 
            .limit(55)  
           }else{
            return ref
            .where("role", "==" , 'user')
            .orderBy('timeStamp','desc') 
            .limit(55)
            .startAfter(lasteRecord) 
           }
       }).valueChanges()
    }

    getUserById(id:any){
        return this.firestore.collection('user').doc(id).get()
    }

    claimResetPoint(phone, point){
        return new Promise<any>((resolve, reject) =>{
            this.firestore
                .collection('user')
                .doc(phone)  
                .update({point : point})
                .then(res => {
                },
                      err => reject(err)
                );
        });
    }

    is_exitUser(phone:string){
        let isExitUser = this.firestore.collection('user').doc(phone);
        return isExitUser.get()
    }

    loginUser(phone, password){
       return this.firestore.collection('user',
        ref =>{
            return ref.where("phone", "==" , phone)
            .where("password","==",password)
            .where("status","==",true)
        }).valueChanges()
    }

    createUser(data:any){

        let tmp_data = { 
            location : data.location,
            name : data.name,
            password : data.password ,
            phone : data.phone,
            event : data.event,
            point : 0,
            role : "user" ,
            status: true,
            timeStamp : formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSSZZZZZ', 'en'),
        }
    
        return new Promise<any>((resolve, reject) =>{
          this.firestore
              .collection("user")
              .doc(data.phone) 
              .set(tmp_data)
              .then(res => {
                  let encypt:any;
                  let arr_tmp=[];
                  arr_tmp.push(tmp_data);
                  encypt =this.Enc.set('2020$#@$^@piseth', JSON.stringify(arr_tmp));
                  localStorage.setItem('key',encypt);
                  window.location.href="#/profile";

              }, err => reject(err));
        });
    }

    deleteUser(user){
        return this.firestore.collection('user').doc(user).delete();
    }

    isAuthenticated(){
        if (localStorage.getItem('key')) {
            if(localStorage.getItem('key') == null){
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    getRole(){
        if (localStorage.getItem('key')) {
            if(localStorage.getItem('key') == null){
                return 'user';
            }
            this.decypt =JSON.parse(this.Enc.get('2020$#@$^@piseth',localStorage.getItem('key')));
            return this.decypt[0].role;
        } else {
            return 'user';
        }
    }
    
    createQRCode(data:any){

        let tmp_data = { 
            code:data.code,
            point:parseInt(data.point),
            status: true,
            user:'',
            scan_date:''
           
        }
    
        return new Promise<any>((resolve, reject) =>{
          this.firestore
              .collection("qrCode")
              .doc(data.code) 
              .set(tmp_data)
              .then(res => {
                 console.log('QR Generate Successed')
              }, err => reject(err));
        });
    }

    is_validCode(code:string){
        let isValidCode = this.firestore.collection('qrCode').doc(code);
        return isValidCode.valueChanges()
    }

    updateUserPoint(phone:string, point:number, code:string){
        return new Promise<any>((resolve, reject) =>{
            this.firestore
                .collection('user')
                .doc(phone)  
                .update({point : point})
                .then(res => {
                    // this.firestore
                    // .collection('qrCode')
                    // .doc(code) 
                    // .update({status:false,
                    //          scan_date:formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSSZZZZZ', 'en'),
                    //          user:phone
                    //         })
                },
                      err => reject(err)
                );
        });
    }

    updateQRCodeStatus(code, phone){
        return new Promise<any>((resolve, reject) =>{
            this.firestore
                .collection('qrCode')
                .doc(code)  
                .update({status:false,
                        scan_date:formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSSZZZZZ', 'en'),
                        user:phone
                })
                .then(res => {
                },
                      err => reject(err)
                );
        });
    }

    searchUser(searchKey){

        if(/^[0-9]+$/.test(searchKey)){
            return this.firestore.collection('user',
            ref =>{
                return ref
                .where('phone','==',searchKey)
            }).valueChanges()
        }else{
            return this.firestore.collection('user',
            ref =>{
                return ref
                .where('location','==',searchKey)
            }).valueChanges()
        }

    }

    updateUserStatus(user, status){
        return new Promise<any>((resolve, reject) =>{
            this.firestore
                .collection('user')
                .doc(user)  
                .update({status : status})
                .then(res => {
                   
                },
                      err => reject(err)
                );
        });
    }

    createUserClaim(data:any){
        let currentDate=new Date()
        let id = (currentDate.getTime()).toString();
        let tmp_data = { 
            id:id,
            location : data.location,
            phone : data.phone,
            name : data.name,
            point : data.point,
            reward : data.reward ,
            status: 'padding',
            timeStamp : formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSSZZZZZ', 'en'),
        }
        return new Promise<any>((resolve, reject) =>{
          this.firestore
            .collection('user')
            .doc(data.phone) 
            .update({point:data.update_point})
            .then(res => {
                    this.firestore
                    .collection("rewardHistory")
                    .doc(id) 
                    .set(tmp_data)
                    alert('ការស្នើរង្វាន់បានជោគជ័យ ចូលរង់ចាំការត្រួតពិនិត្យសំណើរ');

              }, err => reject(err));
        });
        
        // return new Promise<any>((resolve, reject) =>{
        //   this.firestore
        //       .collection("rewardHistory")
        //       .doc(id) 
        //       .set(tmp_data)
        //       .then(res => {
        //             this.firestore
        //             .collection('user')
        //             .doc(data.phone) 
        //             .update({point:data.update_point})

        //             alert('ការស្នើរង្វាន់បានជោគជ័យ ចូលរង់ចាំការត្រួតពិនិត្យសំណើរ');

        //       }, err => reject(err));
        // });
    }

    getUserClaimRewardHistory( lasteRecord, phone ){
        return this.firestore.collection('rewardHistory',
        ref =>{
            if(lasteRecord == null){
             return ref
             .where('phone','==',phone)
             .orderBy('timeStamp','desc') 
             .limit(55)  
            }else{
             return ref
             .where('phone','==', phone)
             .orderBy('timeStamp','desc') 
             .limit(55)
             .startAfter(lasteRecord) 
            }
        }).valueChanges()
    }

    getClaimReward( lasteRecord ){
        return this.firestore.collection('rewardHistory',
        ref =>{
            if(lasteRecord == null){
             return ref
             .orderBy('timeStamp','desc') 
             .limit(55)  
            }else{
             return ref
             .orderBy('timeStamp','desc') 
             .limit(55)
             .startAfter(lasteRecord) 
            }
        }).valueChanges()
    }

    getClaimPaddingRequest(){
        return this.firestore.collection('rewardHistory',
        ref =>{
            return ref
            .where('status','==', 'padding')
        }).valueChanges()
    }

    updateClaimStatus(id, status){
        return new Promise<any>((resolve, reject) =>{
            this.firestore
                .collection('rewardHistory')
                .doc(id)  
                .update({status : status})
                .then(res => {

                },
                      err => reject(err)
                );
        });
    }

    searchUserClaim(searchKey){
        return this.firestore.collection('rewardHistory',
        ref =>{
            return ref
            .where('phone','==',searchKey)
        }).valueChanges()
    }

    getRewardList(){
        return this.firestore.collection('reward').valueChanges()
    }

    getSpecailRewardList(){
        return this.firestore.collection('specailReward').valueChanges()
    }


    // load data from JSON
    getPromotionPage(){
        return this.http.get("../assets/data/promotion.json");
    }

    getEventPage(){
        return this.http.get("../assets/data/event.json");
    }

    getEvent2Page(){
        return this.http.get("../assets/data/event2.json");
    }

    getHomePage(){
        return this.http.get("../assets/data/home.json");
    }
}
